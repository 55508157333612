import { graphql, Link } from "gatsby"
import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"

interface AllWordpressPost {
  edges: Array<{
    node: {
      id: string
      title: string
      excerpt: string
      slug: string
    }
  }>
}
interface Props {
  data: {
    allWordpressPost: AllWordpressPost
  }
}

export const pageQuery = graphql`
  query {
    allWordpressPost(sort: { fields: [date] }, limit: 100) {
      edges {
        node {
          id
          title
          excerpt
          slug
        }
      }
    }
  }
`

const IndexPage: React.FC<Props> = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <h1>My WordPress Blog</h1>
    <h4>Posts</h4>
    {data.allWordpressPost.edges.map(({ node }) => (
      <div key={node.id}>
        <Link to={node.slug}>
          <p>{node.title}</p>
        </Link>
        <div dangerouslySetInnerHTML={{ __html: node.excerpt }} />
      </div>
    ))}
  </Layout>
)

export default IndexPage
